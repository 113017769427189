<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 楼栋统计 -->
              <div class="range" id="ldtj">
                <h4 class="title">楼栋统计</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击系统主页左侧栏目空间管理下“楼栋统计”跳转出楼栋统计页面（如图4.1）页面上方有目前园区总体楼栋数量、楼栋面积、可出租率等，下方可详细查看每个楼栋的房间总数和出租未出租面积
                </p>
                <el-image 
                style="width:100%"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图4.1</p>
              </div>

              <!-- 空间查询 -->
              <div class="range" id="kjcx">
                <h4 class="title">空间查询</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击空间管理下面“空间查询”跳转至空间查询页面（如图4.2），可在页面上方红框处对楼栋、楼层、物业类型、房间状态、租赁状态和面积进行筛选，也可点击下方蓝色字体“楼栋楼层”、“门牌名称”跳转至目标楼栋楼室具体信息页面进行查看
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图4.2</p>
              </div>
              <!-- 基础设置 -->
              <div class="range" id="jcsz">
                <h4 class="title">基础设置</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击空间管理下方“基础设置”跳转至园区平面图（如图4.3）可在红色方框内选择对应楼栋、“上传园区平面图”和“新增楼栋”功能
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图4.3</p>
                <p class="describe">
                  2.也可在黄色方框内点击“楼层设置”跳转出选择楼栋的平面图（如图4.4）图中的红蓝色房间标签都可使用鼠标拖拽移动
                </p>
                <el-image 
                style="width:100%"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图4.4</p>
                <p class="describe">
                  3.点击右上角“新增房间标签”跳转出房间添加页面（如图4.5），填入房间信息即可完成添加,其中可选择这间房间是否统计，若不统计则房间面积不计入楼栋统计的可出租总面积中，若选择不显示，则不会在小程序端展示此空房源给客户。
                </p>
                <el-image 
                style="width:100%"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图4.5</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "楼栋统计",
          target: "#ldtj",
        },
        {
          name: "空间查询",
          target: "#kjcx",
        },
        {
          name: "基础设置",
          target: "#jcsz",
        },
      ],
      link: 0,
      img: {
        aurl: [this.$img + "kjgl/4.1.png"],
        burl: [this.$img + "kjgl/4.2.png"],
        curl: [this.$img + "kjgl/4.3.png"],
        durl: [this.$img + "kjgl/4.4.png"],
        eurl: [this.$img + "kjgl/4.5.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 10) {
        this.tolink(0);
      } else if (result >= 10 && result < 31) {
        this.tolink(1);
      } else if (result >= 31 && result < 52) {
        this.tolink(2);
      } else {
        this.tolink(3);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>